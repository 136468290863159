@media (max-width: 486px) {
  .top-nav {
    width: 100%;
    display: flex;
    flex-direction: column;

    color: #4e4e4e;
  }

  .top-nav .first-line {
    display: flex;
    background-color: white;
    justify-content: space-between;

    padding: 0.5em 0em;
  }

  .top-nav .first-line .logo-box {
    display: flex;
    justify-content: flex-start;
    width: 55%;
    align-items: center;
    margin: 0.3em;
  }

  .top-nav .first-line .logo-box span {
    font-size: 1.7em;
    cursor: pointer;
    margin: 0em 0.3em;
    color: #4e4e4e;
  }

  .top-nav .first-line .tel-box {
    padding: 0.4em 0.5em;
    font-size: 1.2em;
    font-weight: bolder;
  }

  .top-nav .second-line {
    display: flex;
    background-color: #FDD900;
    justify-content: right;
    /* justify-content: space-between; */
  }

  .top-nav .main-logo {
    padding: 0em 4em;
  }

  .top-nav .main-logo img {
    width: 7em;
    height: 100%;
  }

  .top-nav .menu-list {
    display: flex;
    align-items: center;
    padding-right: 1.3em;
  }

  .top-nav .menu-list div {
    display: none;
  }

  .top-nav .menu-list span {
    font-size: 3em;
    font-weight: bold;
  }

  .top-nav .menu-list div:hover {
    color: #ffffff;
  }

  .mobile-menu {
    height: 0;
    background-color: #FDD900;
    /* position: fixed; */
    /* top: 0; */
    /* border-radius: 0 0 30px 30px; */
    z-index: 2;
    overflow: hidden;
    /* box-shadow: -3px 0px 10px rgb(46,42,39,30%); */
    /* transition: var(--mainTransition); */
    transition: all .5s;
  }

  .mobile-menu div {
    padding: 0.5em;
    font-size: 2em;
    font-weight: bold;
    height: 12vh;
  }

  .top-nav .show-menu {
    background-color: #FDD900;
    /* position: fixed; */
    height: 100vh;
  }

  .top-nav .show-menu div {
    padding: 0.5em;
    font-size: 2em;
    height: 12vh;
    font-weight: bold;
    /* cursor: pointer; */
  }
}

@media (min-width: 486px) {

  .top-nav {
    z-index: 2;
    position: fixed;
    top: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .top-nav .first-line {
    display: flex;
    background-color: white;
    justify-content: right;
  }

  .top-nav .first-line .logo-box {
    margin-right: 0.2em;
  }

  .top-nav .first-line .logo-box span {
    font-size: 1.7em;
    color: #4e4e4e;
    padding: 0.7em 0em;
    margin: 0em 0.4em;
    cursor: pointer;
  }

  .top-nav .first-line .tel-box {
    padding: 0.5em 1em;
    background-color: #4e4e4e;
    
    color: #FDD900;
    font-size: 1.5em;
    font-weight: bolder;
  }

  .top-nav .second-line {
    display: flex;
    background-color: #FDD900;
    justify-content: space-between;
  }

  .top-nav .main-logo {
    padding: 0em 4em;
    justify-content: flex-start;
  }

  .top-nav .main-logo img {
    width: 6em;
    height: 100%;
  }

  .top-nav .menu-list {
    display: flex;
    padding: 0em 5em;
    align-items: center;
  }

  .top-nav .menu-list div {
    width: 7em;
    font-size: 1.5em;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #4e4e4e;
    cursor: pointer;
  }

  .top-nav .menu-list span {
    display: none;
  }

  .top-nav .menu-list div:hover {
    color: #ffffff;
  }

  .mobile-menu {
    display: none;
  }
}
