@media (max-width: 486px) {
  .company-page {
    color: #4e4e4e;
  }

  .company-page .section {
    padding-top: 8em;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.6em;
  }

  .company-page .section img {
    width: 70%;
  }

  .company-page .section1 .desc1 .desc1-title {
    margin-bottom: 0.5em;
    font-size: 1.3em;
    font-weight: bolder;
  }

  .company-page .section .desc {
    margin-top: 3em;
    text-align: center;
    width: 90%;
    white-space: pre-line;
    line-height: 1.7em;
  }

  .company-page .section2 img {
    border-radius: 50%;
  }

  .company-page .section3 .desc-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .company-page .section3 .desc-img span{
    font-size: 1.2em;
  }  

  .company-page .section3 span.arrow {
    font-size: 0px;
  }

  .company-page .section3 span.arrow::after{
    content: "arrow_downward";
    font-size: 15px;
  }

  .company-page .section3 .desc-img .sub-desc {
    width: 30%;
    font-size: 1.3em;
    font-weight: bold;
    /* background-color: #fdd900; */
    border: 1px solid black;
    margin: 1em 2em;
    padding: 0.4em;
    border-radius: 1em;
    white-space: pre-line;
  }

  .company-page .video-section {
    padding-top: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .company-page .video-des {
    padding-top: 2em;
    font-size: 1.5em;
  }

  .company-page .video-section iframe {
    /* width: 90%; */
  }

  .company-page .video-section .video-player {
    padding: 2em 0em;
    width: 80% !important;
    height: auto !important;
  }

  .company-page .section4 .section4-title {
    font-size: 1.8em;
    font-weight: bolder;
  }

  .company-page .section4 .company-history {
    text-align: left;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 5em;
    font-size: 1.2em;
  }
}

@media (min-width: 486px) {
  .company-page {
    padding-top: 9.5em;
    color: #4e4e4e;
  }

  .company-page .section1 {
    padding-top: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .company-page .section1 img {
    width: 20em;
  }

  .company-page .section1 .desc1 {
    margin-top: 3em;
    text-align: center;
    width: 70%;
    white-space: pre-line;
    font-size: 1.2em;
    line-height: 1.7em;
  }

  .company-page .section1 .desc1 .desc1-title {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  .company-page .section2 {
    padding-top: 8em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .company-page .section2 img {
    width: 30em;
    border-radius: 50%;
  }

  .company-page .section2 .desc2 {
    margin-top: 3em;
    text-align: center;
    width: 70%;
    white-space: pre-line;
    font-size: 1.2em;
    line-height: 1.7em;
  }

  .company-page .section3 {
    padding-top: 6em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .company-page .section3 .desc-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60em;
  }

  .company-page .section3 .desc-img .sub-desc {
    width: 20%;
    font-size: 1.4em;
    /* background-color: #fdd900; */
    border: 1px solid black;
    margin: 1em 2em;
    padding: 0.4em;
    border-radius: 1em;
    white-space: pre-line;
  }

  .company-page .section3 .desc-img span{
    font-size: 3em;
  }  

  .company-page .section3 .desc3 {
    margin-top: 1em;
    text-align: center;
    width: 70%;
    white-space: pre-line;
    font-size: 1.2em;
    line-height: 1.7em;
  }

  .company-page .video-section {
    padding-top: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .company-page .video-des {
    padding-top: 2em;
    font-size: 1.5em;
  }

  .company-page .video-section iframe {
    width: 700px;
    height: 505px;
  }

  .company-page .video-section .video-player {
    padding: 2em 0em;
  }

  .company-page .section4 {
    padding-top: 6em;
    display: flex;
    flex-direction: column;
    /* align-items: left; */
  }

  .company-page .section4 .section4-title {
    font-size: 3em;
  }

  .company-page .section4 .company-history {
    text-align: left;
    font-size: 1.5em;
    padding-left: 30%;
    padding-right: 30%;
  }
}