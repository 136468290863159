@media (max-width: 486px) {
  .main-page {
    color: #4e4e4e;
  }

  .modal_img{
    width: 90%;
  }

  .main-page .section1 {
    background-color: #fdd900; 
    
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 80vh; */
    max-height: 80vh;
    padding-top: 3em;

    color: #4e4e4e;
    font-size: 1.5em;
    font-weight: bold;
      
    white-space: pre-line;
  }

  .main-page .section1 .text-hello {
    font-size: 2.5em;
    font-weight: bolder;
    letter-spacing: 0.1em;
  }

  .main-page .section1 img {
    display: block;
    max-height: 50vh;
  }

  .main-page .section2 {
    background-color: white;
    color: #4e4e4e;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 2em 1em;

  }

  .main-page .section2 div p {
    white-space: pre-line;
    line-height: 1.5em;  
  }

  .main-page .section2 .tel-box {
    border-bottom: 1px solid #4e4e4e;
    font-size: 1.2em;
    padding-bottom: 1.5em;
  }

  .main-page .section2 .text-box {
    font-size: 1.6em;
    padding-bottom: 0.5em;
  }

  .main-page .section2 .cate-link {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 350px) {

    .main-page .section2 .cate-link div {
      display: flex;
      /* justify-content: flex-start; */
      margin-bottom: 2em;
      justify-content: space-between;
      /* align-items: center; */
    }

    .main-page .section2 .cate-link .link-button {
      color: #4e4e4e;
      font-size: 1.5em;
      font-weight: bold;
      text-decoration: underline;
      letter-spacing: 0.05em;
      width: 45%;
      margin-left: 1em;
    }

    .main-page .section2 ul {
      margin: 0em 0em;
      padding: 0em;
      width: 50%;
    }

    .main-page .section2 li {
      font-size: 0.85em;
      text-align: center;
      list-style-type: none;
      margin: 0.2em 0em;
    }
  }

  @media (max-width: 350px) {
    .main-page .section2 .cate-link div {
      display: flex;
      flex-direction: column;
      /* justify-content: flex-start; */
      justify-content: space-between;
      /* align-items: center; */
    }    

    .main-page .section2 .cate-link .link-button {
      color: #4e4e4e;
      font-size: 1.5em;
      font-weight: bold;
      text-decoration: underline;
      letter-spacing: 0.05em;
      margin-bottom: 0.5em;
    }

    .main-page .section2 ul {
      margin: 0em 0em;
      padding: 0em;
      margin-bottom: 1.5em;
    }

    .main-page .section2 li {
      font-size: 0.85em;
      text-align: center;
      list-style-type: none;
      margin: 0.2em 0em;
    }
  }

  .main-page .section3 {
    display: flex;
    justify-content: center;
    padding: 2.5em;
    background-color: #fdd900;
  }

  .main-page .section3 h2 {
    font-size: 1.3em;
  }

  .main-page .section3 span {
    display: none;
  }

  .main-page .section3 .text-box {
    line-height: 1.8em;
  }

  .main-page .section4 {
    display: flex;
    margin: 3em 0em;
  }

  .main-page .section4 img {
    width: 100%;
  }

  .main-page .section5 {
    display: flex;
    flex-direction: column;
    background-color: #fdd900;

    margin: 2em 0em;
    padding-top: 3em;
  }

  .main-page .section5 .product-part {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .main-page .section5 .product-part .product-title {
    font-size: 2em;
    font-weight: bolder;
  }

  .main-page .section5 .product-part .product-des {
    white-space: pre-line;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.6em;
  }

  .main-page .section5 .product-part .link-button {
    background-color: #4e4e4e;
    color: #fdd900;
    padding: 0.6em;
    border-radius: 1.5em;
    cursor: pointer;
    width: 40%;
    align-self: center;
  }

  .main-page .section5 img {
    width: 100%;
  }
}

@media (min-width: 486px) {
  .main-page {
    padding-top: 9.5em;
    color: #4e4e4e;
  }

  .modal_img {
    width: 90%;
  }

  .main-page .section1 {
    background-color: #fdd900; 
    
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    max-height: 80vh;
    padding-top: 3em;

    color: #4e4e4e;
    font-size: 2em;
    font-weight: bold;
      
    white-space: pre-line;
  }

  .main-page .section1 .text-hello {
    font-size: 2.5em;
    font-weight: bolder;
    letter-spacing: 0.1em;
  }

  .main-page .section1 img {
    display: block;
    max-height: 50vh;
  }

  .main-page .section2 {
    background-color: white;
    color: #4e4e4e;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3em;
    padding-bottom: 5em;
  }

  .main-page .section2 div p {
    white-space: pre-line;
    line-height: 1.5em;  
  }

  .main-page .section2 .tel-box {
    border-bottom: 1px solid #4e4e4e;
    font-size: 1.5em;
    padding-bottom: 1.5em;
  }

  .main-page .section2 .text-box {
    font-size: 2em;
    padding-bottom: 0.5em;
  }

  .main-page .section2 .cate-link {
    display: flex;
    justify-content: space-between;
  }

  .main-page .section2 .cate-link div {
    cursor: pointer;
  }

  .main-page .section2 .cate-link div {
    margin: 0em 2em;
  }

  .main-page .section2 .link-button {
    background-color: white;
    border: none;
    color: #4e4e4e;
    font-size: 1.5em;
    font-weight: bold;
    border-bottom: 1px solid #4e4e4e;
    padding-bottom: 0.2em;
    letter-spacing: 0.05em;
  }

  .main-page .section2 ul {
    margin: 1em 0em;
    padding: 0px;
  }

  .main-page .section2 li {
    font-size: 1em;
    /* text-align: left; */
    list-style-type: none;
    padding: 0.2em 0em;
  }

  .main-page .section3 {
    background-color: #fdd900;
    display: flex;
    height: 17em;
    justify-content: space-between;
    align-items: center;
    padding: 1em 3em;
  }

  .main-page .section3 span {
    font-size: 3em;
  }

  .main-page .section3 .text-box {
    width: 50%;
    line-height: 1.8em;
  }

  .main-page .section4 {
    display: flex;
    padding: 2em;
  }

  .main-page .section4 img {
    position: relative;
    height: 30em;
    margin: 0em 0.5em;
  }

  .main-page .section5 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fdd900;
    margin-bottom: 1em;

    padding: 2em 0em;
  }

  .main-page .section5 .product-part {
    padding-right: 3em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .main-page .section5 .product-part .product-title {
    font-size: 3em;
    font-weight: bolder;
  }

  .main-page .section5 .product-part .product-des {
    white-space: pre-line;
    text-align: right;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.6em;
  }

  .main-page .section5 .product-part .link-button {
    background-color: #4e4e4e;
    color: #fdd900;
    padding: 0.6em;
    border-radius: 1.5em;
    cursor: pointer;
  }

  .main-page .section5 img {
    width: 30%;
    /* height: 70vh; */
  }
}
